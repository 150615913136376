<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AppCard from './AppCard.vue'

export default {
  name: 'AppsList',

  components: { AppCard },

  props: {
    list: {
      type: Array,
      required: true
    },

    counters: {
      type: Array,
      required: true
    },

    searchString: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      openedId: undefined,
      filteredList: undefined
      // alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isAdmin']),

    alphabet () {
      return this.filteredList?.map(item => item.title?.[0]?.toUpperCase()).filter((item, index, arr) => arr.indexOf(item) === index) || []
    },

    showAddButton () {
      return [2291, 3088, 680305, 189432, 23421, 528978, 168627, 6, 20, 4095].includes(this.user?.id)
    }
  },

  watch: {
    searchString: {
      handler () {
        this.$nuxt?.$loading?.start?.()
        const list = this.list.filter(app => (app.categories?.map(c => this.showCategory(c))?.reduce((a, b) => +a + +b, 0) || 0) > 0)
        const startsWith = list?.filter(item => item.title?.toLowerCase().startsWith(this.searchString.toLowerCase())) || []
        const justIncludes = list?.filter(item => item.title?.toLowerCase().indexOf(this.searchString.toLowerCase()) > 0) || []
        this.filteredList = [...startsWith, ...justIncludes]
        this.scrollToTop()
        this.$nuxt?.$loading?.finish?.()
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    counterForCategory (category) {
      return this.counters?.find(c => c.application_category_id === category.id)?.count || (this.counters?.length ? '0' : '')
    },

    onSale (category) {
      return +this.counterForCategory(category) > 0
    },

    showCategory (category) {
      return this.isAdmin || this.onSale(category)
    },

    setOpened (id) {
      if (id === this.openedId) {
        this.openedId = undefined
      } else {
        this.openedId = id
        this.$gtag.event(`exact_${this.list?.find(a => a.id === id)?.app_type}_marketplace_click`, { account_id: this.user?.id, game_id: id })
      }
    },

    scrollToLetter (s) {
      if (!window?.document) {
        return
      }

      const elements = window.document.getElementsByClassName(`start_from_${s?.toLowerCase()}`)
      elements?.[0]?.scrollIntoView({ behavior: 'smooth' })
    },

    scrollToTop () {
      if (process.client) {
        if (!window?.document) {
          return
        }

        const element = window.document.getElementById?.('apps_list_container')
        element?.scrollIntoView?.({ behavior: 'smooth' })
      }
    },

    addNewApp () {
      this.showModal({
        component: 'AddNewAppModal',
        data: {
          appType: this.list?.[0]?.app_type || 'game'
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <div id="apps_list_container" :class="$style.fake"></div>
    <div :class="$style.container">
      <div :class="$style.list">
        <div v-if="showAddButton" :class="$style.itemAdd" @click="addNewApp">
          <img :class="$style.itemAdd__icon" :src="require('~/assets/img/icons/plus.svg')" alt="plus" />
          {{ $t('addApplication') }}
        </div>
        <template v-if="list && filteredList?.length">
          <div v-for="item in filteredList" :key="item.id" :class="$style.item">
            <AppCard :application="item" :opened="openedId" :counters="counters" @setOpened="setOpened" />
          </div>
        </template>
        <template v-else>
          <div :class="$style.notFound">
            <img :src="require(`~/assets/img/icons/sad.svg`)" :class="$style.notFound__icon" alt="sad" />
            <p :class="$style.notFound__text">
              {{ $t('noResults') }}
            </p>
          </div>
        </template>
      </div>
      <div :class="$style.alphabet">
        <span v-for="s in alphabet" :key="s" :class="$style.alphabet__letter" @click="scrollToLetter(s)">
          {{ s }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media (min-width: $breakpointDesktopWide) {
    flex-direction: row-reverse;
  }
}

.fake {
  height: 1px;
  width: 100%;
  background: transparent;
  scroll-margin: 140px;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  width: 100%;

  @media (min-width: $breakpointTablet) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $breakpointDesktopWide) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.alphabet {
  position: sticky;
  top: 120px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2px;
  width: 12px;
  margin-left: 18px;
  color: $blue-grey10;
  cursor: pointer;

  @media (min-width: $breakpointTablet) {
    top: 158px;
  }

  @media (min-width: $breakpointDesktopWide) {
    margin-left: 0;
    margin-right: 40px;
  }

  &__letter {
    font-size: 12px;
    line-height: 105%;
    font-weight: 500;

    @media (min-width: $breakpointDesktopWide) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.notFound {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;

  @media (min-width: $breakpointTablet) {
    grid-column: 1 / -1;
  }

  &__icon {
    width: 48px;
    height: 48px;
  }

  &__text {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
}

.item {
  background: $blue-grey70;
  border-radius: 8px;

  &Add {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue-grey10;
    background: $blue-grey50;
    border-radius: 8px;
    padding: 12px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      background: $blue-grey40;
    }

    &__icon {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
  }
}
</style>
