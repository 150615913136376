<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'AppCard',

  props: {
    application: {
      type: Object,
      required: true
    },

    counters: {
      type: Array,
      required: true
    },

    opened: {
      type: Number,
      default: undefined
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isAdmin']),

    isOpened () {
      return this.opened === this.application?.id
    },

    categories () {
      return this.application?.categories.filter(c => this.showCategory(c))
    },

    appPath () {
      return this.application?.seo_data?.path
    },

    openIconStyles () {
      return {
        [this.$style.icon]: true,
        [this.$style.iconOpened]: this.isOpened,
        [`start_from_${this.application?.title[0]?.toLowerCase()}`]: true
      }
    },

    showAddButton () {
      return [2291, 3088, 680305, 189432, 23421, 528978, 168627, 6, 20, 4095].includes(this.user?.id)
    },

    tagsStyles () {
      return {
        [this.$style.tags]: true,
        [this.$style.tagsOpened]: this.isOpened
      }
    },

    appUrl () {
      return this.localeLocation(`/marketplace/${this.appPath}`)
    },

    locale () {
      return this.$i18n.locale?.split?.('-')[0] || 'en'
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    setOpened () {
      this.$emit('setOpened', this.application?.id)
    },

    openAppWithCategory (category) {
      this.$router.push(this.localeLocation(`/marketplace/${this.application?.id}?category=${category?.id}`))
    },

    appWithCategoryLink (category) {
      return this.localeLocation(`/marketplace/${this.appPath}/${category.seo_data?.path}`)
    },

    badSeo (id) {
      const seo = this.categories?.find(c => c.id === id)?.seo_data?.[this.locale]
      return !(seo?.title?.length && seo?.productTitle?.length && seo?.h1?.length && seo?.article?.length && seo?.keywords?.length && seo?.description?.length)
    },

    categoryStyles (id) {
      return {
        [this.$style.tag]: true,
        [this.$style.tagNoSeo]: this.isAdmin && this.badSeo(id)
      }
    },

    addAppCategory () {
      this.showModal({
        component: 'AddNewCategoryModal',
        data: {
          appId: this.application?.id
        }
      })
    },

    counterForCategory (category) {
      return this.counters?.find(c => c.application_category_id === category.id)?.count || (this.counters?.length ? '0' : '')
    },

    onSale (category) {
      return +this.counterForCategory(category) > 0
    },

    showCategory (category) {
      return this.isAdmin || this.onSale(category)
    },

    categoryName (category) {
      return category.translations?.[this.$i18n.locale.split('-')[0]]?.category_name || this.$t(category.category_name?.trim())
    }
  }
}
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.card" @click="setOpened">
      <template v-if="application.cover">
        <img
          :class="$style.cover"
          :src="application.icon"
          alt="cover"
          loading="lazy"
          height="40"
          width="40"
        />
      </template>
      <div v-else />

      <span :class="$style.title">
        <NuxtLink :to="appUrl">
          {{ application.title }}
        </NuxtLink>
      </span>

      <img :class="openIconStyles" :src="require('~/assets/img/icons/angle-right.svg')" alt="angle right" />
    </div>

    <div :class="tagsStyles">
      <NuxtLink v-for="category in categories" :key="category.id" :class="categoryStyles(category.id)" :to="appWithCategoryLink(category)">
        {{ categoryName(category) }} ({{ counterForCategory(category) }})
      </NuxtLink>

      <div v-if="showAddButton" :class="[$style.tag, $style.tagWithIcon]" @click="addAppCategory">
        <img :class="$style.tag__icon" :src="require('~/assets/img/icons/plus.svg')" alt="plus" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  color: $white;
  background: $blue-grey70;
  border-radius: 8px;
  padding: 12px;
}

.card {
  display: grid;
  grid-template-columns: 40px 1fr 20px;
  grid-gap: 12px;
  width: 100%;
  align-items: center;
}

.cover {
  border-radius: 4px;
}

.title {
  color: $white;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.icon {
  width: 20px;
  height: 20px;
  rotate: 90deg;
  scroll-margin: 140px;
  cursor: pointer;

  &Opened {
    rotate: 270deg;
  }
}

.tags {
  display: none;

  @media (min-width: $breakpointDesktopWide) {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $blue-grey40;
    padding-top: 8px;
  }

  &Opened {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $blue-grey40;
    padding-top: 8px;
  }
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: $blue-grey50;
  color: $white;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &WithIcon {
    background: $blue-grey40;

    &:hover {
      background: $blue-grey30;
    }
  }

  &NoSeo {
    background: $red60-20 !important;
  }

  &__icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}
</style>
